import { TabMatchNextMatch } from "../components/TabMatchNextMatch"
import { useState, useEffect } from "react"

const URL = "/api/getFortniteData"

type MatchData = {
  displaytitle: string;
  team1: string;
  team2: string;
  timestamp: string;
};

export const FortniteTeam = () => {
  const [DataFuturMatch, setDataFuturMatch] = useState<MatchData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(URL);
      const json = await result.json();
      setDataFuturMatch(json);
    };

    fetchData();
  }, []);

  return (
    <div className="content-center mt-20">
      <div className="flex justify-center">
      {DataFuturMatch.length === 0 ? (
        <p className="text-white font-sans flex justify-center">Aucun match à venir</p>
      ) : (
        <div>
        <div className="flex justify-center">
          <p className="text-white font-sans">Les prochains matchs sont :</p>
        </div>
        <TabMatchNextMatch jsonDataFuturMatch={DataFuturMatch} />
      </div>
      )}
   {/*  <p className="text-white font-sans">Les derniers résultats sont :</p>*/}
    </div>
    </div>
  );
};
